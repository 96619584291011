import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>This site is under construction</h2>
        <p />
        <a
          className="App-link"
          href="http://topgear.enginerebuilders.in"
          target="_blank"
          rel="noopener noreferrer"
        >
          TopGear Engine Rebuilders
        </a>
        <p />
        <a
          className="App-link"
          href="http://metro.enginerebuilders.in"
          target="_blank"
          rel="noopener noreferrer"
        >
          Metro Engine Rebuilders
        </a>
      </header>
    </div>
  );
}

export default App;
